<template>
  <v-container grid-list-xl style="max-width: 1200px; margin: 0 auto;" pa-0>
    <v-layout style="margin:0">
      <v-flex xs12 pa-0>
        <v-tabs
          v-model="selectedProfileMenuItem"
          background-color="transparent"
          centered
          center-active
          slider-color="accent"
          show-arrows
        >
          <v-tab
            v-for="item in menuItems"
            :key="item.title"
            :href="'#' + item.title"
            class="overflow-hidden"
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </v-flex>
    </v-layout>
    <v-layout style="margin:0">
      <v-flex xs12 pa-0>
        <v-tabs-items v-model="selectedProfileMenuItem">
          <v-tab-item
            v-for="item in menuItems"
            :key="item.title"
            :value="item.title"
          >
            <v-flex pa-0 xs12 mx-auto>
              <component :is="item.component" />
            </v-flex>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Profile',
  components: {
    Messages: () =>
      import(
        /* webpackChunkName: "Messages" */ '@/components/Profile/Messages'
      ),
    UserTickets: () =>
      import(
        /* webpackChunkName: "UserTickets" */ '@/components/Profile/UserTickets'
      ),
    Settings: () =>
      import(/* webpackChunkName: "Settings" */ '@/components/Profile/Settings')
  },
  data() {
    return {
      menuItems: [
        {
          title: this.$vuetify.lang.t('$vuetify.profileMenuItems.messages'),
          component: 'Messages'
        },
        {
          title: this.$vuetify.lang.t('$vuetify.profileMenuItems.userTickets'),
          component: 'UserTickets'
        },
        {
          title: this.$vuetify.lang.t('$vuetify.profileMenuItems.settings'),
          component: 'Settings'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['selectedProfileMenuItem']),
    selectedProfileMenuItem: {
      get() {
        return this.$store.state.app.selectedProfileMenuItem
      },
      set(value) {
        this.$store.commit('app/setSelectedProfileMenuItem', value)
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    openStore() {
      this.$router.replace('/posts')
    }
  },
  head: {
    title() {
      return {
        inner: 'Profile'
      }
    },
    meta: [
      {
        name: 'description',
        content:
          'GKas Sa jäid pileti ostamisega hiljaks? Või just sooviksid oma teatri, kontserti pileti edasi müüa? Piletivahetus aitab!',
        id: 'desc'
      }
    ]
  }
}
</script>
